import {
  CREATE_BARCODE_PICKER,
  DESTROY_BARCODE_PICKER,
  LOCK_BARCODE_PICKER,
  PAUSE_BARCODE_PICKER,
  RELEASE_BARCODE_PICKER,
  RESUME_BARCODE_PICKER,
} from "../../actions/BarcodePicker/BarcodePicker";

const InitialState = {
  busy: false,
  paused: false,
  ready: false,
  lock: null,
};

let barcodePicker;

export default function barcodeService(state = InitialState, action) {
  switch (action.type) {
    case CREATE_BARCODE_PICKER:
      barcodePicker = action.barcodePicker;
      return {
        ...state,
        ready: true,
      };
    case DESTROY_BARCODE_PICKER:
      barcodePicker.destroy();
      return {
        ...state,
        ready: false,
      };
    case PAUSE_BARCODE_PICKER:
      barcodePicker.pauseScanning(false);
      return {
        ...state,
        paused: true,
        lock: action.lock,
      };
    case RESUME_BARCODE_PICKER:
      barcodePicker.resumeScanning();
      return {
        ...state,
        paused: false,
      };
    case RELEASE_BARCODE_PICKER:
      clearTimeout(state.lock);
      return {
        ...state,
        paused: true,
        busy: false,
        lock: null,
      };
    case LOCK_BARCODE_PICKER:
      return {
        ...state,
        paused: true,
        busy: true,
      };
    default:
      return state;
  }
}
