import axios from "axios";

import Config from "../../config/env/config.json";

export function getMusterList(filter) {
  return async (dispatch) => {
    dispatch(MusterListRequest());
    return axios
      .get(`${Config.api.endpoint}/emergencies/muster`)
      .then((res) => {
        if (res.data.data.muster) {
          res.data.data.muster = filter(res.data.data.muster);
          dispatch(MusterListSuccess(res.data, null));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(MusterListFailure(err));
      });
  };
}

export function getMusterLocation() {
  return async (dispatch) => {
    dispatch(MusterLocationRequest());
    return axios
      .get(`${Config.api.endpoint}/locations`)
      .then((res) => {
        dispatch(MusterLocationSuccess(res.data));
      })
      .catch((err) => {
        dispatch(MusterLocationFailure(err));
      });
  };
}

export const GET_MUSTER_LIST_REQUEST = "GET_MUSTER_LIST_REQUEST";
export function MusterListRequest() {
  return {
    type: GET_MUSTER_LIST_REQUEST,
  };
}

export const GET_MUSTER_LIST_SUCCESS = "GET_MUSTER_LIST_SUCCESS";
export function MusterListSuccess(data, message) {
  return {
    type: GET_MUSTER_LIST_SUCCESS,
    data,
    message,
  };
}

export const GET_MUSTER_LIST_FAILURE = "GET_MUSTER_LIST_FAILURE";
export function MusterListFailure(err) {
  return {
    type: GET_MUSTER_LIST_FAILURE,
    err,
  };
}

export const GET_MUSTER_LOCATION_REQUEST = "GET_MUSTER_LOCATION_REQUEST";
export function MusterLocationRequest() {
  return {
    type: GET_MUSTER_LOCATION_REQUEST,
  };
}

export const GET_MUSTER_LOCATION_SUCCESS = "GET_MUSTER_LOCATION_SUCCESS";
export function MusterLocationSuccess(data) {
  return {
    type: GET_MUSTER_LOCATION_SUCCESS,
    data,
  };
}

export const GET_MUSTER_LOCATION_FAILURE = "GET_MUSTER_LOCATION_FAILURE";
export function MusterLocationFailure(err) {
  return {
    type: GET_MUSTER_LOCATION_FAILURE,
    err,
  };
}

export const MUSTER_LOCATION_SELECT = "MUSTER_LOCATION_SELECT";
export function MusterLocationSelect(id) {
  return {
    type: MUSTER_LOCATION_SELECT,
    id,
  };
}

export const CLEAR_MUSTER_LOCATION = "CLEAR_MUSTER_LOCATION";
export function ClearMusterLocation() {
  return {
    type: CLEAR_MUSTER_LOCATION,
  };
}
