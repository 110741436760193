import axios from "axios";

import Config from "../../config/env/config.json";

export function handleCompliance(payload, networkId) {
  return async (dispatch) => {
    dispatch(checkComplianceRequest());
    return axios
      .post(`${Config.api.endpoint}/scans/compliance?networkId=${networkId}`, payload)
      .then((response) => {
        dispatch(checkComplianceSuccess(response.data));
      })
      .catch((_) => {
        return;
      });
  };
}

export function handleSearch(networkId, query) {
  return async (dispatch) => {
    dispatch(scanSearchRequest());
    return axios
      .post(`${Config.api.endpoint}/scans/search?networkId=${networkId}`, {
        keyword: query,
      })
      .then((response) => {
        dispatch(scanSearchSuccess(response.data));
      })
      .catch((error) => {
        const is408 = error.message.includes("408");
        dispatch(scanSearchFailure(is408 ? "Try a more specific search" : undefined));
      });
  };
}

export function handleRecentScans(gateId) {
  const gatePath = gateId ? `?gateId=${gateId}` : "";
  return async (dispatch) => {
    dispatch(RecentScansRequest());
    return axios
      .get(`${Config.api.endpoint}/scans${gatePath}`)
      .then((response) => {
        dispatch(RecentScansSuccess(response.data.data.scans));
        dispatch(setNetworkId(response.data.network));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function handleCheckIn(payload, networkId, direction, override, handleCheckInResponse, overrideValue) {
  return async (dispatch) => {
    dispatch(checkInRequest(payload.student_id));
    return axios
      .post(`${Config.api.endpoint}/scans?networkId=${networkId}`, payload)
      .then((response) => {
        if (direction === "in" && override) {
          handleOverride(response.data.data.id, networkId, overrideValue, dispatch);
        }

        handleCheckInResponse("Success!");
        dispatch(checkInSuccess(response.data, direction));
      })
      .catch((err) => {
        handleCheckInResponse("Failed!");
        dispatch(checkInFailure(err));
      });
  };
}

function handleOverride(id, networkId, overrideValue, dispatch) {
  const payload = {
    access: overrideValue,
  };

  axios
    .patch(`${Config.api.endpoint}/scans/${id}?networkId=${networkId}`, payload)
    .then((response) => {
      dispatch(OverrideResponse(response.data.data.message));
    })
    .catch((err) => {
      console.log(err);
    })
    .catch((err) => {
      console.log(err);
    });
}

export const OVERRIDE_RESPONSE = "OVERRIDE_RESPONSE";
function OverrideResponse(data) {
  return {
    type: OVERRIDE_RESPONSE,
    data,
  };
}

export const RECENTSCANS_REQUEST = "RECENTSCANS_REQUEST";
export function RecentScansRequest() {
  return {
    type: RECENTSCANS_REQUEST,
  };
}

export const RECENTSCANS_SUCCESS = "RECENTSCANS_SUCCESS";
export function RecentScansSuccess(data) {
  return {
    type: RECENTSCANS_SUCCESS,
    data,
  };
}

export const SET_NETWORK_ID = "SET_NETWORK_ID";
export function setNetworkId(network) {
  return {
    type: SET_NETWORK_ID,
    networkId: network.id,
  };
}

export const CHECK_IN_REQUEST = "CHECK_IN_REQUEST";
export function checkInRequest(id) {
  return {
    type: CHECK_IN_REQUEST,
    id,
  };
}

export const CHECK_IN_SUCCESS = "CHECK_IN_SUCCESS";
export function checkInSuccess(data, direction) {
  return {
    type: CHECK_IN_SUCCESS,
    data,
    direction,
  };
}

export const CHECK_IN_FAILURE = "CHECK_IN_FAILURE";
export function checkInFailure(err) {
  return {
    type: CHECK_IN_FAILURE,
    err,
  };
}

export const SCANSEARCH_REQUEST = "SCANSEARCH_REQUEST";
export function scanSearchRequest() {
  return {
    type: SCANSEARCH_REQUEST,
  };
}

export const SCANSEARCH_CLEAR = "SCANSEARCH_CLEAR";
export function scanSearchClear() {
  return {
    type: SCANSEARCH_CLEAR,
  };
}

export const SCANSEARCH_SUCCESS = "SCANSEARCH_SUCCESS";
export function scanSearchSuccess(data) {
  return {
    type: SCANSEARCH_SUCCESS,
    data,
  };
}

export const SCANSEARCH_FAILURE = "SCANSEARCH_FAILURE";
export function scanSearchFailure(err) {
  return {
    type: SCANSEARCH_FAILURE,
    err,
  };
}

export const CHECK_COMPLIANCE_SUCCESS = "CHECK_COMPLIANCE_SUCCESS";
export function checkComplianceSuccess(data) {
  return {
    type: CHECK_COMPLIANCE_SUCCESS,
    data,
  };
}

export const CHECK_COMPLIANCE_REQUEST = "CHECK_COMPLIANCE_REQUEST";
export function checkComplianceRequest() {
  return {
    type: CHECK_COMPLIANCE_REQUEST,
  };
}
