export const CREATE_BARCODE_PICKER = "CREATE_BARCODE_PICKER";
export function createBarcodePicker(barcodePicker) {
  return {
    type: CREATE_BARCODE_PICKER,
    barcodePicker,
  };
}

export const DESTROY_BARCODE_PICKER = "DESTROY_BARCODE_PICKER";
export function destroyBarcodePicker() {
  return {
    type: DESTROY_BARCODE_PICKER,
  };
}

export const PAUSE_BARCODE_PICKER = "PAUSE_BARCODE_PICKER";
export function pauseBarcodePicker(lock) {
  return {
    type: PAUSE_BARCODE_PICKER,
    lock,
  };
}

export const RESUME_BARCODE_PICKER = "RESUME_BARCODE_PICKER";
export function resumeBarcodePicker() {
  return {
    type: RESUME_BARCODE_PICKER,
  };
}

export const RELEASE_BARCODE_PICKER = "RELEASE_BARCODE_PICKER";
export function releaseBarcodePicker() {
  return {
    type: RELEASE_BARCODE_PICKER,
  };
}

export const LOCK_BARCODE_PICKER = "LOCK_BARCODE_PICKER";
export function lockBarcodePicker() {
  return {
    type: LOCK_BARCODE_PICKER,
  };
}
